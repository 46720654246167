import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header({ handleLogout }) {
  return (
    <div className="header">
      <div className="header__left">
        <h1>quuqle</h1>
      </div>
      <div className="header__center">
        <input type="text" placeholder="Search" />
      </div>
      <div className="header__right">
        <Link to="/newsfeed">Home</Link>
        <Link to="/friend-requests">Friend Requests</Link>
        <Link to="/profile">Profile</Link>
        <button onClick={handleLogout} className="logout-button">Logout</button>
      </div>
    </div>
  );
}

export default Header;


/*
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './Header.css';

function Header({ token, setToken }) {
  const history = useHistory();

  const handleLogout = () => {
    setToken('');
    localStorage.removeItem('token');
    history.push('/login');
  };

  return (
    <div className="header">
      <div className="header__left">
        <h1>MySocial</h1>
      </div>
      <div className="header__center">
        <input type="text" placeholder="Search" />
      </div>
      <div className="header__right">
        <Link to="/newsfeed">Home</Link>
        <Link to="/friend-requests">Friend Requests</Link>
        <Link to="/profile">Profile</Link>
        {!token ? (
          <Link to="/register">Register</Link>
        ) : (
          <button onClick={handleLogout}>Logout</button>
        )}
      </div>
    </div>
  );
}

export default Header;

*/
