import React, { useEffect, useState, useRef } from 'react';
import axios from '../axiosConfig';
import './Chat.css';

function Chat({ friendId, friendName, handleCloseChat, socket, messages, updateMessages, userId }) {
  const [message, setMessage] = useState('');
  const messagesEndRef = useRef(null);

  useEffect(() => {
    console.log('Friend ID:', friendId);
    console.log('User ID in Chat:', userId);

    async function fetchMessages() {
      try {
        const response = await axios.get(`https://quuqle.com:3001/messages/${friendId}`);
        console.log('Response data:', response.data);

        if (Array.isArray(response.data)) {
          updateMessages(friendId, response.data);
        } else {
          console.error('Unexpected response format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    }

    fetchMessages();
  }, [friendId, updateMessages]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const sendMessage = (e) => {
    e.preventDefault();
    if (socket && socket.readyState === WebSocket.OPEN) {
      const messageData = { recipientId: friendId, content: message };
      socket.send(JSON.stringify(messageData));
      updateMessages(friendId, [...messages, { senderId: userId, content: message }]);
      setMessage('');
    } else {
      console.error('WebSocket is not open');
    }
  };

  return (
    <div className="chat-wrapper">
      <div className="chat-header">
        <h2>{friendName}</h2>
        <button onClick={() => handleCloseChat(friendId)} className="close-btn">X</button>
      </div>
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`chat-message ${msg.senderId === userId ? 'my-message' : 'their-message'}`}>
            <span>{msg.content}</span>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <form className="chat-form" onSubmit={sendMessage}>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message"
        />
        <button type="submit" className="send-button">
          <div className="light-mode" style={{"--chat-composer-button-color": "var(--disabled-icon)", "--chat-composer-input-background-color": "var(--comment-background)", "--chat-edit-message-overlay-color": "var(--surface-background)", "--chat-outgoing-message-background-gradient": "initial", "--chat-outgoing-message-bubble-background-color": "#0084ff", "--mwp-header-button-color": "var(--disabled-icon)", "--mwp-message-list-actions-gap": "0px", "--mwp-message-list-actions-width": "calc(84px + 3*0px)", "--mwp-message-row-background": "var(--messenger-card-background)", "--mwp-primary-theme-color": "#0084ff", "--reaction-pill-multireact-selected-color": "#c7e4ff", "--primary-icon": "var(--chat-composer-button-color, var(--accent))"}}>
            <div aria-label="Lähetä painamalla Enteriä" className="test" role="button" tabIndex="0">
              <svg viewBox="0 0 12 13" width="20" height="20" fill="currentColor" className="send-button">
                <g fillRule="evenodd" transform="translate(-450 -1073)">
                  <path d="m458.371 1079.75-6.633.375a.243.243 0 0 0-.22.17l-.964 3.255c-.13.418-.024.886.305 1.175a1.08 1.08 0 0 0 1.205.158l8.836-4.413c.428-.214.669-.677.583-1.167-.06-.346-.303-.633-.617-.79l-8.802-4.396a1.073 1.073 0 0 0-1.183.14c-.345.288-.458.77-.325 1.198l.963 3.25c.03.097.118.165.22.17l6.632.375s.254 0 .254.25-.254.25-.254.25"></path>
                </g>
              </svg>
              <div className="test2" role="none" data-visualcompletion="ignore" style={{ inset: '0px' }}></div>
            </div>
          </div>
        </button>
      </form>
    </div>
  );
}

export default Chat;

