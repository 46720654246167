import React, { useState } from 'react';

function SendFriendRequest({ token }) {
  const [recipientId, setRecipientId] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://quuqle.com:3001/send-friend-request', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Include token here
        },
        body: JSON.stringify({ recipientId })
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error sending friend request:', errorData);
        return;
      }

      const data = await response.json();
      console.log('Friend request sent successfully:', data);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <div>
      <h2>Send Friend Request</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" value={recipientId} onChange={(e) => setRecipientId(e.target.value)} placeholder="Recipient ID" required />
        <button type="submit">Send Request</button>
      </form>
    </div>
  );
}

export default SendFriendRequest;
