import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import './Post.css';

function Post({ post }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [showAllComments, setShowAllComments] = useState(false);
  const [error, setError] = useState('');
  const [loveCount, setLoveCount] = useState(post.love_count || 0);
  const [loveUsers, setLoveUsers] = useState([]);
  const [showLoveUsers, setShowLoveUsers] = useState(false);
  const [embedContent, setEmbedContent] = useState(null);

  useEffect(() => {
    fetchFirstComment();
    fetchEmbedContent();
  }, [post.id]);

  const fetchFirstComment = async () => {
    try {
      const response = await axios.get(`https://quuqle.com:3001/api/comments/${post.id}?limit=1`, {
        withCredentials: true
      });
      if (Array.isArray(response.data)) {
        setComments(response.data);
      } else {
        console.log('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.log('Error fetching first comment:', error);
      setError('Error fetching first comment');
    }
  };

  const fetchComments = async () => {
    try {
      const response = await axios.get(`https://quuqle.com:3001/api/comments/${post.id}`, {
        withCredentials: true
      });
      if (Array.isArray(response.data)) {
        setComments(response.data);
      } else {
        console.log('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.log('Error fetching comments:', error);
      setError('Error fetching comments');
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://quuqle.com:3001/api/comments', { postId: post.id, content: newComment }, {
        withCredentials: true
      });
      if (response.data && response.data.id) {
        setNewComment('');
        fetchComments();
      } else {
        console.log('Error:', response.data.message);
      }
    } catch (error) {
      console.log('Error adding comment:', error);
      setError('Error adding comment');
    }
  };

const handleLovePost = async () => {
  try {
    const response = await axios.post(`https://quuqle.com:3001/posts/${post.id}/love`, {}, {
      withCredentials: true
    });
    setLoveCount(loveCount + 1);
    setLoveUsers(response.data.loveUsers);
  } catch (error) {
    if (error.response && error.response.status === 400) {
      alert('You have already loved this post');
    } else {
      console.log('Error loving post:', error);
    }
  }
};


  const handleMouseEnter = async () => {
    try {
      const response = await axios.get(`https://quuqle.com:3001/posts/${post.id}/love_users`, {
        withCredentials: true
      });
      setLoveUsers(response.data.loveUsers);
      setShowLoveUsers(true);
    } catch (error) {
      console.log('Error fetching love users:', error);
    }
  };

  const handleMouseLeave = () => {
    setShowLoveUsers(false);
  };

  const fetchEmbedContent = async () => {
    if (post.content.includes('soundcloud.com')) {
      try {
        const response = await axios.get(`https://quuqle.com:3001/soundcloud_embed`, {
          params: { url: post.content },
          withCredentials: true,
        });
        setEmbedContent(response.data.html);
      } catch (error) {
        console.log('Error fetching SoundCloud embed content:', error);
      }
    }
  };

  const embedYouTube = (text) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/g;
    return (text || '').replace(regex, (match, p1) => (
      `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p1}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>`
    ));
  };

  return (
    <div className="post">
      <div className="post-header">
        <h2>{post.username}</h2>
      </div>
      <div className="post-content">
        {embedContent ? (
          <div dangerouslySetInnerHTML={{ __html: embedContent }}></div>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: embedYouTube(post.content) }}></div>
        )}
        {post.mediaUrl && (
          post.mediaUrl.endsWith('.mp4') ? (
            <video controls className="post-media">
              <source src={`https://quuqle.com:3001/uploads/${post.mediaUrl}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={`https://quuqle.com:3001/uploads/${post.mediaUrl}`} alt="Post media" className="post-media" />
          )
        )}
      </div>
      <div className="post-actions">
        <button onClick={handleLovePost} className="love-button"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Love
        </button>
        <span>{loveCount} {loveCount === 1 ? 'Love' : 'Loves'}</span>
        {showLoveUsers && (
          <div className="love-users-tooltip">
            {loveUsers.map(user => (
              <div key={user.username}>{user.username}</div>
            ))}
          </div>
        )}
      </div>
      <div className="post-comments">
        {error && <div className="error">{error}</div>}
        <ul>
          {comments.map(comment => (
            <li key={comment.id} className="comment-item">
              <span className="comment-username">{comment.username}</span>:&nbsp;
              <span dangerouslySetInnerHTML={{ __html: embedYouTube(comment.content) }}></span>
            </li>
          ))}
        </ul>
        {!showAllComments && comments.length > 0 && (
          <a
            className="show-comments-link"
            href="#"
            onClick={(e) => { e.preventDefault(); fetchComments(); setShowAllComments(true); }}
          >
            Show More Comments
          </a>
        )}
      </div>
      <div className="post-comment-form">
        <form onSubmit={handleCommentSubmit}>
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment"
            className="comment-input"
          />
          <button type="submit" className="comment-button">Comment</button>
        </form>
      </div>
    </div>
  );
}

export default Post;












/*import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import './Post.css';

function Post({ post }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [showAllComments, setShowAllComments] = useState(false);
  const [error, setError] = useState('');
  const [loveCount, setLoveCount] = useState(post.love_count || 0);
  const [loveUsers, setLoveUsers] = useState([]);
  const [showLoveUsers, setShowLoveUsers] = useState(false);

  useEffect(() => {
    fetchFirstComment();
  }, [post.id]);

  const fetchFirstComment = async () => {
    try {
      const response = await axios.get(`http://quuqle.com:3001/api/comments/${post.id}?limit=1`, {
        withCredentials: true
      });
      if (Array.isArray(response.data)) {
        setComments(response.data);
      } else {
        console.log('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.log('Error fetching first comment:', error);
      setError('Error fetching first comment');
    }
  };

  const fetchComments = async () => {
    try {
      const response = await axios.get(`http://quuqle.com:3001/api/comments/${post.id}`, {
        withCredentials: true
      });
      if (Array.isArray(response.data)) {
        setComments(response.data);
      } else {
        console.log('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.log('Error fetching comments:', error);
      setError('Error fetching comments');
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://quuqle.com:3001/api/comments', { postId: post.id, content: newComment }, {
        withCredentials: true
      });
      if (response.data && response.data.id) {
        setNewComment('');
        fetchComments();
      } else {
        console.log('Error:', response.data.message);
      }
    } catch (error) {
      console.log('Error adding comment:', error);
      setError('Error adding comment');
    }
  };

  const handleLovePost = async () => {
    try {
      const response = await axios.post(`http://quuqle.com:3001/posts/${post.id}/love`, {}, {
        withCredentials: true
      });
      setLoveCount(loveCount + 1);
      setLoveUsers(response.data.loveUsers);
    } catch (error) {
      console.log('Error loving post:', error);
    }
  };

  const handleMouseEnter = async () => {
    try {
      const response = await axios.get(`http://quuqle.com:3001/posts/${post.id}/love_users`, {
        withCredentials: true
      });
      setLoveUsers(response.data.loveUsers);
      setShowLoveUsers(true);
    } catch (error) {
      console.log('Error fetching love users:', error);
    }
  };

  const handleMouseLeave = () => {
    setShowLoveUsers(false);
  };

  const embedYouTube = (text) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/g;
    return (text || '').replace(regex, (match, p1) => (
      `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p1}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>`
    ));
  };

  return (
    <div className="post">
      <div className="post-header">
        <h2>{post.username}</h2>
      </div>
      <div className="post-content">
        <div dangerouslySetInnerHTML={{ __html: embedYouTube(post.content) }}></div>
        {post.mediaUrl && (
          post.mediaUrl.endsWith('.mp4') ? (
            <video controls className="post-media">
              <source src={`http://quuqle.com:3001/uploads/${post.mediaUrl}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={`http://quuqle.com:3001/uploads/${post.mediaUrl}`} alt="Post media" className="post-media" />
          )
        )}
      </div>
      <div className="post-actions">
        <button onClick={handleLovePost} className="love-button"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Love
        </button>
        <span>{loveCount} {loveCount === 1 ? 'Love' : 'Loves'}</span>
        {showLoveUsers && (
          <div className="love-users-tooltip">
            {loveUsers.map(user => (
              <div key={user.username}>{user.username}</div>
            ))}
          </div>
        )}
      </div>
      <div className="post-comments">
        {error && <div className="error">{error}</div>}
        <ul>
          {comments.map(comment => (
            <li key={comment.id} className="comment-item">
              <span className="comment-username">{comment.username}</span>:&nbsp;
              <span dangerouslySetInnerHTML={{ __html: embedYouTube(comment.content) }}></span>
            </li>
          ))}
        </ul>
        {!showAllComments && comments.length > 0 && (
          <a
            className="show-comments-link"
            href="#"
            onClick={(e) => { e.preventDefault(); fetchComments(); setShowAllComments(true); }}
          >
            Show More Comments
          </a>
        )}
      </div>
      <div className="post-comment-form">
        <form onSubmit={handleCommentSubmit}>
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment"
            className="comment-input"
          />
          <button type="submit" className="comment-button">Comment</button>
        </form>
      </div>
    </div>
  );
}

export default Post;
*/







/*import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import './Post.css';

function Post({ post }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [showAllComments, setShowAllComments] = useState(false);
  const [error, setError] = useState('');
  const [loveCount, setLoveCount] = useState(post.love_count || 0);
  const [loveUsers, setLoveUsers] = useState([]);

  useEffect(() => {
    fetchFirstComment();
  }, [post.id]);

  const fetchFirstComment = async () => {
    try {
      const response = await axios.get(`http://quuqle.com:3001/api/comments/${post.id}?limit=1`, {
        withCredentials: true
      });
      if (Array.isArray(response.data)) {
        setComments(response.data);
      } else {
        console.log('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.log('Error fetching first comment:', error);
      setError('Error fetching first comment');
    }
  };

  const fetchComments = async () => {
    try {
      const response = await axios.get(`http://quuqle.com:3001/api/comments/${post.id}`, {
        withCredentials: true
      });
      if (Array.isArray(response.data)) {
        setComments(response.data);
      } else {
        console.log('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.log('Error fetching comments:', error);
      setError('Error fetching comments');
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://quuqle.com:3001/api/comments', { postId: post.id, content: newComment }, {
        withCredentials: true
      });
      if (response.data && response.data.id) {
        setNewComment('');
        fetchComments();
      } else {
        console.log('Error:', response.data.message);
      }
    } catch (error) {
      console.log('Error adding comment:', error);
      setError('Error adding comment');
    }
  };

  const handleLovePost = async () => {
    try {
      const response = await axios.post(`http://quuqle.com:3001/posts/${post.id}/love`, {}, {
        withCredentials: true
      });
      setLoveCount(loveCount + 1);
      setLoveUsers(response.data.loveUsers);
    } catch (error) {
      console.log('Error loving post:', error);
    }
  };

  const embedYouTube = (text) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/g;
    return (text || '').replace(regex, (match, p1) => (
      `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p1}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>`
    ));
  };

  return (
    <div className="post">
      <div className="post-header">
        <h2>{post.username}</h2>
      </div>
      <div className="post-content">
        <div dangerouslySetInnerHTML={{ __html: embedYouTube(post.content) }}></div>
        {post.mediaUrl && (
          post.mediaUrl.endsWith('.mp4') ? (
            <video controls className="post-media">
              <source src={`http://quuqle.com:3001/uploads/${post.mediaUrl}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={`http://quuqle.com:3001/uploads/${post.mediaUrl}`} alt="Post media" className="post-media" />
          )
        )}
      </div>
      <div className="post-actions">
        <button onClick={handleLovePost} className="love-button">Love</button>
        <span>{loveCount} {loveCount === 1 ? 'Love' : 'Loves'}</span>
        {loveUsers.length > 0 && (
          <div className="love-users-tooltip">
            {loveUsers.map(user => (
              <div key={user.username}>{user.username}</div>
            ))}
          </div>
        )}
      </div>
      <div className="post-comments">
        {error && <div className="error">{error}</div>}
        <ul>
          {comments.map(comment => (
            <li key={comment.id} className="comment-item">
              <span className="comment-username">{comment.username}</span>:&nbsp;
              <span dangerouslySetInnerHTML={{ __html: embedYouTube(comment.content) }}></span>
            </li>
          ))}
        </ul>
        {!showAllComments && comments.length > 0 && (
          <a
            className="show-comments-link"
            href="#"
            onClick={(e) => { e.preventDefault(); fetchComments(); setShowAllComments(true); }}
          >
            Show More Comments
          </a>
        )}
      </div>
      <div className="post-comment-form">
        <form onSubmit={handleCommentSubmit}>
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment"
            className="comment-input"
          />
          <button type="submit" className="comment-button">Comment</button>
        </form>
      </div>
    </div>
  );
}

export default Post;
*/






/*import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import './Post.css';

function Post({ post }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [showAllComments, setShowAllComments] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchFirstComment();
  }, [post.id]);

  const fetchFirstComment = async () => {
    try {
      const response = await axios.get(`http://quuqle.com:3001/api/comments/${post.id}?limit=1`, {
        withCredentials: true
      });
      if (Array.isArray(response.data)) {
        setComments(response.data);
      } else {
        console.log('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.log('Error fetching first comment:', error);
      setError('Error fetching first comment');
    }
  };

  const fetchComments = async () => {
    try {
      const response = await axios.get(`http://quuqle.com:3001/api/comments/${post.id}`, {
        withCredentials: true
      });
      if (Array.isArray(response.data)) {
        setComments(response.data);
      } else {
        console.log('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.log('Error fetching comments:', error);
      setError('Error fetching comments');
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://quuqle.com:3001/api/comments', { postId: post.id, content: newComment }, {
        withCredentials: true
      });
      if (response.data && response.data.id) {
        setNewComment('');
        fetchComments(); // Fetch all comments after posting a new comment
      } else {
        console.log('Error:', response.data.message);
      }
    } catch (error) {
      console.log('Error adding comment:', error);
      setError('Error adding comment');
    }
  };

  const embedYouTube = (text) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/g;
    return (text || '').replace(regex, (match, p1) => (
      `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p1}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>`
    ));
  };

  return (
    <div className="post">
      <div className="post-header">
        <h2>{post.username}</h2>
      </div>
      <div className="post-content">
        <div dangerouslySetInnerHTML={{ __html: embedYouTube(post.content) }}></div>
        {post.mediaUrl && (
          post.mediaUrl.endsWith('.mp4') ? (
            <video controls className="post-media">
              <source src={`http://quuqle.com:3001/uploads/${post.mediaUrl}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={`http://quuqle.com:3001/uploads/${post.mediaUrl}`} alt="Post media" className="post-media" />
          )
        )}
      </div>
      <div className="post-comments">
        {error && <div className="error">{error}</div>}
        <ul>
          {comments.map(comment => (
            <li key={comment.id} className="comment-item">
              <span className="comment-username">{comment.username}</span>:&nbsp;
              <span dangerouslySetInnerHTML={{ __html: embedYouTube(comment.content) }}></span>
            </li>
          ))}
        </ul>
        {!showAllComments && comments.length > 0 && (
          <a
            className="show-comments-link"
            href="#"
            onClick={(e) => { e.preventDefault(); fetchComments(); setShowAllComments(true); }}
          >
            Show More Comments
          </a>
        )}
      </div>
      <div className="post-comment-form">
        <form onSubmit={handleCommentSubmit}>
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment"
            className="comment-input"
          />
          <button type="submit" className="comment-button">Comment</button>
        </form>
      </div>
    </div>
  );
}

export default Post;

*/





/*import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import './Post.css';

function Post({ post }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [showAllComments, setShowAllComments] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchFirstComment();
  }, [post.id]);

  const fetchFirstComment = async () => {
    try {
      const response = await axios.get(`http://quuqle.com:3001/api/comments/${post.id}?limit=1`, {
        withCredentials: true
      });
      if (Array.isArray(response.data)) {
        setComments(response.data);
      } else {
        console.log('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.log('Error fetching first comment:', error);
      setError('Error fetching first comment');
    }
  };

  const fetchComments = async () => {
    try {
      const response = await axios.get(`http://quuqle.com:3001/api/comments/${post.id}`, {
        withCredentials: true
      });
      if (Array.isArray(response.data)) {
        setComments(response.data);
      } else {
        console.log('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.log('Error fetching comments:', error);
      setError('Error fetching comments');
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://quuqle.com:3001/api/comments', { postId: post.id, content: newComment }, {
        withCredentials: true
      });
      if (response.data && response.data.id) {
        setNewComment('');
        fetchComments(); // Fetch all comments after posting a new comment
      } else {
        console.log('Error:', response.data.message);
      }
    } catch (error) {
      console.log('Error adding comment:', error);
      setError('Error adding comment');
    }
  };

  const embedYouTube = (text) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/g;
    return (text || '').replace(regex, (match, p1) => (
      `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p1}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>`
    ));
  };

  return (
    <div className="post">
      <div className="post-header">
        <h2>{post.username}</h2>
      </div>
      <div className="post-content">
        <div dangerouslySetInnerHTML={{ __html: embedYouTube(post.content) }}></div>
        {post.mediaUrl && (
          post.mediaUrl.endsWith('.mp4') ? (
            <video controls className="post-media">
              <source src={`http://quuqle.com:3001/uploads/${post.mediaUrl}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={`http://quuqle.com:3001/uploads/${post.mediaUrl}`} alt="Post media" className="post-media" />
          )
        )}
      </div>
      <div className="post-comments">
        {error && <div className="error">{error}</div>}
        <ul>
          {comments.map(comment => (
            <li key={comment.id} className="comment-item">
              <div dangerouslySetInnerHTML={{ __html: embedYouTube(comment.content) }}></div>
            </li>
          ))}
        </ul>
        {!showAllComments && comments.length > 0 && (
          <a
            className="show-comments-link"
            href="#"
            onClick={(e) => { e.preventDefault(); fetchComments(); setShowAllComments(true); }}
          >
            Show More Comments
          </a>
        )}
      </div>
      <div className="post-comment-form">
        <form onSubmit={handleCommentSubmit}>
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment"
            className="comment-input"
          />
          <button type="submit" className="comment-button">Comment</button>
        </form>
      </div>
    </div>
  );
}

export default Post;

*/






/*import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import './Post.css';

function Post({ post }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [showAllComments, setShowAllComments] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchFirstComment();
  }, [post.id]);

  const fetchFirstComment = async () => {
    try {
      const response = await axios.get(`http://quuqle.com:3001/api/comments/${post.id}?limit=1`, {
        withCredentials: true
      });
      if (Array.isArray(response.data)) {
        setComments(response.data);
      } else {
        console.log('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.log('Error fetching first comment:', error);
      setError('Error fetching first comment');
    }
  };

  const fetchComments = async () => {
    try {
      const response = await axios.get(`http://quuqle.com:3001/api/comments/${post.id}`, {
        withCredentials: true
      });
      if (Array.isArray(response.data)) {
        setComments(response.data);
      } else {
        console.log('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.log('Error fetching comments:', error);
      setError('Error fetching comments');
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://quuqle.com:3001/api/comments', { postId: post.id, content: newComment }, {
        withCredentials: true
      });
      if (response.data && response.data.id) {
        setNewComment('');
        fetchComments(); // Fetch all comments after posting a new comment
      } else {
        console.log('Error:', response.data.message);
      }
    } catch (error) {
      console.log('Error adding comment:', error);
      setError('Error adding comment');
    }
  };

  const embedYouTube = (text) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/g;
    return (text || '').replace(regex, (match, p1) => (
      `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p1}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>`
    ));
  };

  return (
    <div className="post">
      <div className="post-header">
        <h2>{post.username}</h2>
      </div>
      <div className="post-content">
        <div dangerouslySetInnerHTML={{ __html: embedYouTube(post.content) }}></div>
        {post.mediaUrl && post.mediaUrl.endsWith('.mp4') ? (
          <video controls className="post-media">
            <source src={`http://quuqle.com:3001/uploads/${post.mediaUrl}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img src={`http://quuqle.com:3001/uploads/${post.mediaUrl}`} alt="Post media" className="post-media" />
        )}
      </div>
      <div className="post-comments">
        {error && <div className="error">{error}</div>}
        <ul>
          {comments.map(comment => (
            <li key={comment.id} className="comment-item">
              <div dangerouslySetInnerHTML={{ __html: embedYouTube(comment.content) }}></div>
            </li>
          ))}
        </ul>
        {!showAllComments && comments.length > 0 && (
          <a
            className="show-comments-link"
            href="#"
            onClick={(e) => { e.preventDefault(); fetchComments(); setShowAllComments(true); }}
          >
            Show More Comments
          </a>
        )}
      </div>
      <div className="post-comment-form">
        <form onSubmit={handleCommentSubmit}>
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment"
            className="comment-input"
          />
          <button type="submit" className="comment-button">Comment</button>
        </form>
      </div>
    </div>
  );
}

export default Post;
*/



/*import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import './Post.css';

function Post({ post }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [showAllComments, setShowAllComments] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchFirstComment();
  }, [post.id]);

  const fetchFirstComment = async () => {
    try {
      const response = await axios.get(`http://quuqle.com:3001/api/comments/${post.id}?limit=1`, {
        withCredentials: true
      });
      if (Array.isArray(response.data)) {
        setComments(response.data);
      } else {
        console.log('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.log('Error fetching first comment:', error);
      setError('Error fetching first comment');
    }
  };

  const fetchComments = async () => {
    try {
      const response = await axios.get(`http://quuqle.com:3001/api/comments/${post.id}`, {
        withCredentials: true
      });
      if (Array.isArray(response.data)) {
        setComments(response.data);
      } else {
        console.log('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.log('Error fetching comments:', error);
      setError('Error fetching comments');
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://quuqle.com:3001/api/comments', { postId: post.id, content: newComment }, {
        withCredentials: true
      });
      if (response.data && response.data.id) {
        setNewComment('');
        fetchComments(); // Fetch all comments after posting a new comment
      } else {
        console.log('Error:', response.data.message);
      }
    } catch (error) {
      console.log('Error adding comment:', error);
      setError('Error adding comment');
    }
  };

  const embedYouTube = (text) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/g;
    return (text || '').replace(regex, (match, p1) => (
      `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p1}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>`
    ));
  };

  return (
    <div className="post">
      <div className="post-header">
        <h2>{post.username}</h2>
      </div>
      <div className="post-content">
        <div dangerouslySetInnerHTML={{ __html: embedYouTube(post.content) }}></div>
        {post.imageUrl && (
          <div className="post-image">
            <img src={`http://quuqle.com:3001/uploads/${post.imageUrl}`} alt="Post image" />
          </div>
        )}
      </div>
      <div className="post-comments">
        {error && <div className="error">{error}</div>}
        <ul>
          {comments.map(comment => (
            <li key={comment.id} className="comment-item">
              <div dangerouslySetInnerHTML={{ __html: embedYouTube(comment.content) }}></div>
            </li>
          ))}
        </ul>
        {!showAllComments && comments.length > 0 && (
          <a
            className="show-comments-link"
            href="#"
            onClick={(e) => { e.preventDefault(); fetchComments(); setShowAllComments(true); }}
          >
            Show More Comments
          </a>
        )}
      </div>
      <div className="post-comment-form">
        <form onSubmit={handleCommentSubmit}>
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment"
            className="comment-input"
          />
          <button type="submit" className="comment-button">Comment</button>
        </form>
      </div>
    </div>
  );
}

export default Post;

*/
