import React, { useState } from 'react';
import axios from '../axiosConfig';
import { useHistory } from 'react-router-dom';
import './Login.css';

function Login({ setIsAuthenticated }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();

  console.log('setIsAuthenticated:', setIsAuthenticated); // Debug: Check if the function is passed correctly

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Login function called'); // Debug statement
    try {
      const response = await axios.post('https://quuqle.com:3001/auth/login', { email, password }, { withCredentials: true });
      console.log('Response:', response); // Debug statement
      if (response.data.message === 'Login successful') {
        console.log('Login successful'); // Debug statement
        if (typeof setIsAuthenticated === 'function') {
          setIsAuthenticated(true); // Correctly using the function
          console.log('Navigating to newsfeed'); // Debug statement
          history.push('/newsfeed');
        } else {
          console.error('setIsAuthenticated is not a function');
        }
      }
    } catch (error) {
      console.error('Login error:', error.response ? error.response.data : error.message);
      setError(error.response ? error.response.data : error.message); // Set error message
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-left">
          <h1>quuqle</h1>
          <img src="/jyva-my.png" alt="myLogo" className="my-logo" />
          <p>Connect with friends and the world around you on quuqle.</p>
        </div>
        <div className="login-right">
          <form onSubmit={handleSubmit} className="login-form">
            <input
              type="text"
              placeholder="Email or phone number"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit">Log In</button>
            {error && <p className="error">{error}</p>}
            <a href="/forgot-password" className="forgot-password-link">Forgot password?</a>
            <hr />
            <button type="button" className="create-account-button" onClick={() => history.push('/register')}>Create new account</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;


/*import React, { useState } from 'react';
import axios from '../axiosConfig';
import { useHistory } from 'react-router-dom';
import './Login.css';

function Login({ setIsAuthenticated }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Login function called'); // Debug statement
    try {
      const response = await axios.post('https://quuqle.com:3001/auth/login', { email, password }, { withCredentials: true });
      console.log('Response:', response); // Debug statement
      if (response.data.message === 'Login successful') {
        console.log('Login successful'); // Debug statement
        setIsAuthenticated(true);
        history.push('/newsfeed');
      }
    } catch (error) {
      console.error('Login error:', error.response ? error.response.data : error.message);
      setError(error.response ? error.response.data : error.message); // Set error message
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-left">
          <h1>MyBook</h1>
          <img src="/jyva-my.png" alt="myLogo" className="my-logo" />
          <p>Connect with friends and the world around you on MyBook.</p>
        </div>
        <div className="login-right">
          <form onSubmit={handleSubmit} className="login-form">
            <input
              type="text"
              placeholder="Email or phone number"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit">Log In</button>
            {error && <p className="error">{error}</p>}
            <a href="/forgot-password" className="forgot-password-link">Forgot password?</a>
            <hr />
            <button type="button" className="create-account-button" onClick={() => history.push('/register')}>Create new account</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
*/



/*
import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './Login.css';

function Login({ setIsAuthenticated }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Login function called'); // Debug statement
    try {
      const response = await axios.post('http://quuqle.com:3001/auth/login', { email, password }, { withCredentials: true });
      console.log('Response:', response); // Debug statement
      if (response.data.message === 'Login successful') {
        console.log('Login successful'); // Debug statement
        setIsAuthenticated(true);
        history.push('/newsfeed');
      }
    } catch (error) {
      console.error('Login error:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className="login-container">
      <div className="login-header">
        <h1>AceBook</h1>
        <p>AceBookin Avulla pidät yhteyttä elämääsi ihmisiin.</p>
      </div>
      <form onSubmit={handleSubmit} className="login-form">
        <input
          type="text"
          placeholder="Sähköpostiosoite tai puhelinnumero"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Salasana"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Kirjaudu sisään</button>
      </form>
      <div className="login-footer">
        <a href="#">Onko salasana kateissa?</a>
        <button>Luo uusi tili</button>
      </div>
    </div>
  );
}

export default Login;

*/
