import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import './FriendsCarousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const FriendsCarousel = ({ token }) => {
  const [friends, setFriends] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    async function fetchFriends() {
      try {
        const response = await axios.get('https://quuqle.com:3001/possible_friends', {
          headers: { 
            'Authorization': `Bearer ${token}`  // Include token here
          }
        });
        setFriends(response.data);
      } catch (error) {
        console.log('Error fetching friends:', error.response ? error.response.data : error.message);
      }
    }
    fetchFriends();
  }, [token]);

  const handleAddFriend = async (friendId) => {
    try {
      const response = await axios.post('https://quuqle.com:3001/send_friend_request', { friendId }, {
        headers: { 
          'Authorization': `Bearer ${token}`  // Include token here
        }
      });
      setMessage(response.data.message);
      // Optionally remove the friend from the list or update the status
      setFriends(friends.filter(friend => friend.id !== friendId));
    } catch (error) {
      console.log('Error sending friend request:', error.response ? error.response.data : error.message);
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1
  };

  return (
    <div className="carousel-container">
      <h2>People You May Know</h2>
      {message && <p>{message}</p>}
      <Slider {...settings}>
        {friends.map(friend => (
          <div key={friend.id} className="friend-item">
            <img src={`https://quuqle.com:3001/uploads/avatars/${friend.avatar}`} alt={friend.username} className="friend-avatar" />
            <h4>{friend.username}</h4>
            <button className="add-friend-button" onClick={() => handleAddFriend(friend.id)}>Add Friend</button>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default FriendsCarousel;

