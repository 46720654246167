import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import Chat from './Chat';
import './Contacts.css';

const getTokenFromCookies = () => {
  const cookieString = document.cookie;
  const cookies = cookieString.split('; ');
  for (let cookie of cookies) {
    const [name, value] = cookie.split('=');
    if (name === 'token') {
      return value;
    }
  }
  return null; // Return null if token is not found
};

let socket;

function Contacts() {
  const [userId, setUserId] = useState(null);
  const [friends, setFriends] = useState([]);
  const [activeChats, setActiveChats] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [messages, setMessages] = useState({});

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await axios.get('https://quuqle.com:3001/auth/check', { withCredentials: true });
        if (response.status === 200) {
          setUserId(response.data.userId);
          console.log('User ID:', response.data.userId); // Debug log for userId
        }
      } catch (error) {
        console.error('Error fetching user ID:', error);
      }
    };

    fetchUserId();
  }, []);

  useEffect(() => {
    async function fetchFriends() {
      try {
        const response = await axios.get('https://quuqle.com:3001/friends', {
          withCredentials: true
        });
        console.log('Response from /friends API:', response);

        if (Array.isArray(response.data)) {
          console.log('Friends list loaded:', response.data);
          setFriends(response.data);
        } else {
          console.error('Unexpected response format:', response.data);
          setFriends([]);
        }
      } catch (error) {
        console.error('Error fetching friends:', error);
        setFriends([]);
      }
    }

    if (userId) {
      fetchFriends();
    }
  }, [userId]);

  useEffect(() => {
    if (friends.length > 0 && userId) {
      const token = getTokenFromCookies();
      socket = new WebSocket(`wss://quuqle.com:3001?token=${token}`);

      socket.onopen = () => {
        console.log('WebSocket connection opened in Contacts');
      };

      socket.onmessage = (event) => {
        console.log('WebSocket message received in Contacts:', event.data);
        const message = JSON.parse(event.data);
        if (message.type === 'updateUserStatus') {
          console.log('Received online users:', message.onlineUsers);
          setOnlineUsers(message.onlineUsers);
        } else {
          console.log('Processing incoming message:', message);
          handleIncomingMessage(message);
        }
      };

      socket.onerror = (error) => {
        console.error('WebSocket error in Contacts:', error);
      };

      socket.onclose = (event) => {
        console.log('WebSocket connection closed in Contacts:', event);
      };

      return () => {
        if (socket) {
          socket.close();
        }
      };
    }
  }, [friends, userId]);

  const handleIncomingMessage = (message) => {
    console.log('Handling incoming message:', message);
    console.log('Current friends list:', friends);

    setMessages((prevMessages) => {
      const newMessages = { ...prevMessages };
      if (!newMessages[message.senderId]) {
        newMessages[message.senderId] = [];
      }
      newMessages[message.senderId].push(message);
      return newMessages;
    });

    const friend = friends.find(f => f.id === parseInt(message.senderId, 10));
    if (friend) {
      console.log(`Opening chat with friend: ${friend.name}`);
      setActiveChats((prevChats) => {
        const existingChat = prevChats.find(chat => chat.friendId === friend.id);
        if (!existingChat) {
          const newChats = [...prevChats, { friendId: friend.id, friendName: friend.name }];
          if (newChats.length > 3) {
            newChats.shift(); // Close the oldest chat if more than 3
          }
          return newChats;
        }
        return prevChats; // Return previous chats if already exists
      });
    } else {
      console.error('Friend not found in friends list:', message.senderId);
    }
  };

  const handleOpenChat = (friendId, friendName) => {
    console.log(`Attempting to open chat with friendId: ${friendId}, friendName: ${friendName}`);
    setActiveChats((prevChats) => {
      const existingChat = prevChats.find(chat => chat.friendId === friendId);
      if (!existingChat) {
        const newChats = [...prevChats, { friendId, friendName }];
        if (newChats.length > 3) {
          newChats.shift(); // Close the oldest chat if more than 3
        }
        return newChats;
      }
      return prevChats;
    });
  };

  const handleCloseChat = (friendId) => {
    setActiveChats((prevChats) => prevChats.filter(chat => chat.friendId !== friendId));
  };

  const updateMessages = (friendId, newMessages) => {
    setMessages((prevMessages) => {
      const currentMessages = prevMessages[friendId] || [];
      if (JSON.stringify(currentMessages) !== JSON.stringify(newMessages)) {
        return {
          ...prevMessages,
          [friendId]: newMessages,
        };
      }
      return prevMessages;
    });
  };

  return (
    <div className="contacts-container">
      <h3>Conversations</h3>
      <ul>
        {friends.map(friend => (
          <li key={friend.id} onClick={() => handleOpenChat(friend.id, friend.name)}>
            <img src={`https://quuqle.com:3001/uploads/avatars/${friend.avatar}`} alt={friend.name} className="friend-avatar" />
            {onlineUsers.includes(friend.id.toString()) && (
              <span className="online-dot" style={{ backgroundColor: 'green' }}></span>
            )}
            <span className="friend-name">{friend.name}</span>
          </li>
        ))}
      </ul>
      <div className="active-chats">
        {activeChats.map(chat => (
          <Chat
            key={chat.friendId}
            friendId={chat.friendId}
            friendName={chat.friendName}
            handleCloseChat={() => handleCloseChat(chat.friendId)}
            socket={socket}
            messages={messages[chat.friendId] || []}
            updateMessages={updateMessages}
            userId={userId} // Pass userId as a prop
          />
        ))}
      </div>
    </div>
  );
}

export default Contacts;














/*import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import Chat from './Chat';
import './Contacts.css';

const getTokenFromCookies = () => {
  const cookieString = document.cookie;
  const cookies = cookieString.split('; ');
  for (let cookie of cookies) {
    const [name, value] = cookie.split('=');
    if (name === 'token') {
      return value;
    }
  }
  return null; // Return null if token is not found
};

let socket;

function Contacts({ userId }) {
  const [friends, setFriends] = useState([]);
  const [activeChats, setActiveChats] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [messages, setMessages] = useState({});

  useEffect(() => {
    async function fetchFriends() {
      try {
        const response = await axios.get('http://quuqle.com:3001/friends', {
          withCredentials: true
        });
        console.log('Response from /friends API:', response);

        if (Array.isArray(response.data)) {
          console.log('Friends list loaded:', response.data);
          setFriends(response.data);
        } else {
          console.error('Unexpected response format:', response.data);
          setFriends([]);
        }
      } catch (error) {
        console.error('Error fetching friends:', error);
        setFriends([]);
      }
    }

    fetchFriends();
  }, []);

  useEffect(() => {
    if (friends.length > 0) {
      const token = getTokenFromCookies();
      socket = new WebSocket(`ws://quuqle.com:3001?token=${token}`);

      socket.onopen = () => {
        console.log('WebSocket connection opened in Contacts');
      };

      socket.onmessage = (event) => {
        console.log('WebSocket message received in Contacts:', event.data);
        const message = JSON.parse(event.data);
        if (message.type === 'updateUserStatus') {
          console.log('Received online users:', message.onlineUsers);
          setOnlineUsers(message.onlineUsers);
        } else {
          console.log('Processing incoming message:', message);
          handleIncomingMessage(message);
        }
      };

      socket.onerror = (error) => {
        console.error('WebSocket error in Contacts:', error);
      };

      socket.onclose = (event) => {
        console.log('WebSocket connection closed in Contacts:', event);
      };

      return () => {
        if (socket) {
          socket.close();
        }
      };
    }
  }, [friends]);

  const handleIncomingMessage = (message) => {
    console.log('Handling incoming message:', message);
    console.log('Current friends list:', friends);

    setMessages((prevMessages) => {
      const newMessages = { ...prevMessages };
      if (!newMessages[message.senderId]) {
        newMessages[message.senderId] = [];
      }
      newMessages[message.senderId].push(message);
      return newMessages;
    });

    const friend = friends.find(f => f.id === parseInt(message.senderId, 10));
    if (friend) {
      console.log(`Opening chat with friend: ${friend.name}`);
      setActiveChats((prevChats) => {
        const existingChat = prevChats.find(chat => chat.friendId === friend.id);
        if (!existingChat) {
          const newChats = [...prevChats, { friendId: friend.id, friendName: friend.name }];
          if (newChats.length > 3) {
            newChats.shift(); // Close the oldest chat if more than 3
          }
          return newChats;
        }
        return prevChats; // Return previous chats if already exists
      });
    } else {
      console.error('Friend not found in friends list:', message.senderId);
    }
  };

  const handleOpenChat = (friendId, friendName) => {
    console.log(`Attempting to open chat with friendId: ${friendId}, friendName: ${friendName}`);
    setActiveChats((prevChats) => {
      const existingChat = prevChats.find(chat => chat.friendId === friendId);
      if (!existingChat) {
        const newChats = [...prevChats, { friendId, friendName }];
        if (newChats.length > 3) {
          newChats.shift(); // Close the oldest chat if more than 3
        }
        return newChats;
      }
      return prevChats;
    });
  };

  const handleCloseChat = (friendId) => {
    setActiveChats((prevChats) => prevChats.filter(chat => chat.friendId !== friendId));
  };

  const updateMessages = (friendId, newMessages) => {
    setMessages((prevMessages) => {
      const currentMessages = prevMessages[friendId] || [];
      if (JSON.stringify(currentMessages) !== JSON.stringify(newMessages)) {
        return {
          ...prevMessages,
          [friendId]: newMessages,
        };
      }
      return prevMessages;
    });
  };

  return (
    <div className="contacts-container">
      <h3>Contacts</h3>
      <ul>
        {friends.map(friend => (
          <li key={friend.id} onClick={() => handleOpenChat(friend.id, friend.name)}>
            <span className="online-dot" style={{ backgroundColor: onlineUsers.includes(friend.id.toString()) ? 'green' : 'transparent', marginRight: '5px' }}></span>
            <img src={`http://quuqle.com:3001/uploads/avatars/${friend.avatar}`} alt={friend.name} className="friend-avatar" />
            {friend.name}
          </li>
        ))}
      </ul>
      <div className="active-chats">
        {activeChats.map(chat => (
          <Chat
            key={chat.friendId}
            friendId={chat.friendId}
            friendName={chat.friendName}
            handleCloseChat={() => handleCloseChat(chat.friendId)}
            socket={socket}
            messages={messages[chat.friendId] || []}
            updateMessages={updateMessages}
            userId={userId} // Pass userId as a prop
          />
        ))}
      </div>
    </div>
  );
}

export default Contacts;
*/







/*import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import Chat from './Chat';
import './Contacts.css';

const getTokenFromCookies = () => {
  const cookieString = document.cookie;
  const cookies = cookieString.split('; ');
  for (let cookie of cookies) {
    const [name, value] = cookie.split('=');
    if (name === 'token') {
      return value;
    }
  }
  return null; // Return null if token is not found
};

let socket;

function Contacts({ userId }) {
  console.log('Contacts userId:', userId); // Debug log for userId
  const [friends, setFriends] = useState([]);
  const [activeChats, setActiveChats] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [messages, setMessages] = useState({});

  useEffect(() => {
    async function fetchFriends() {
      try {
        const response = await axios.get('http://quuqle.com:3001/friends', {
          withCredentials: true
        });
        console.log('Response from /friends API:', response);

        if (Array.isArray(response.data)) {
          console.log('Friends list loaded:', response.data);
          setFriends(response.data);
        } else {
          console.error('Unexpected response format:', response.data);
          setFriends([]);
        }
      } catch (error) {
        console.error('Error fetching friends:', error);
        setFriends([]);
      }
    }

    fetchFriends();
  }, []);

  useEffect(() => {
    if (friends.length > 0) {
      const token = getTokenFromCookies();
      socket = new WebSocket(`ws://quuqle.com:3001?token=${token}`);

      socket.onopen = () => {
        console.log('WebSocket connection opened in Contacts');
      };

      socket.onmessage = (event) => {
        console.log('WebSocket message received in Contacts:', event.data);
        const message = JSON.parse(event.data);
        if (message.type === 'updateUserStatus') {
          console.log('Received online users:', message.onlineUsers);
          setOnlineUsers(message.onlineUsers);
        } else if (message.type === 'newMessage') {
          console.log('New message received:', message);
          updateMessages(message.senderId, [...(messages[message.senderId] || []), message]);
        }
      };

      socket.onclose = () => {
        console.log('WebSocket connection closed in Contacts');
      };

      socket.onerror = (error) => {
        console.error('WebSocket error in Contacts:', error);
      };
    }
  }, [friends, messages]);

  const handleOpenChat = (friendId, friendName) => {
    console.log(`Open chat with friendId: ${friendId}, friendName: ${friendName}`);
    setActiveChats((prevChats) => {
      const existingChat = prevChats.find(chat => chat.friendId === friendId);
      if (!existingChat) {
        const newChats = [...prevChats, { friendId, friendName }];
        if (newChats.length > 3) {
          newChats.shift(); // Close the oldest chat if more than 3
        }
        return newChats;
      }
      return prevChats;
    });
  };

  const handleCloseChat = (friendId) => {
    setActiveChats((prevChats) => prevChats.filter(chat => chat.friendId !== friendId));
  };

  const updateMessages = (friendId, newMessages) => {
    setMessages((prevMessages) => {
      const currentMessages = prevMessages[friendId] || [];
      if (JSON.stringify(currentMessages) !== JSON.stringify(newMessages)) {
        return {
          ...prevMessages,
          [friendId]: newMessages,
        };
      }
      return prevMessages;
    });
  };

  return (
    <div className="contacts-container">
      <h3>Contacts</h3>
      <ul>
        {friends.map(friend => (
          <li key={friend.id} onClick={() => handleOpenChat(friend.id, friend.name)}>
            <span className="online-dot" style={{ backgroundColor: onlineUsers.includes(friend.id.toString()) ? 'green' : 'transparent', marginRight: '5px' }}></span>
            <img src={`http://quuqle.com:3001/uploads/avatars/${friend.avatar}`} alt={friend.name} className="friend-avatar" />
            {friend.name}
          </li>
        ))}
      </ul>
      <div className="active-chats">
        {activeChats.map(chat => (
          <Chat
            key={chat.friendId}
            friendId={chat.friendId}
            friendName={chat.friendName}
            handleCloseChat={() => handleCloseChat(chat.friendId)}
            socket={socket}
            messages={messages[chat.friendId] || []}
            updateMessages={updateMessages}
            userId={userId} // Pass userId as a prop
          />
        ))}
      </div>
    </div>
  );
}

export default Contacts;

*/
