import React, { useState, useEffect, useCallback } from 'react';
import axios from '../axiosConfig';
import { useDropzone } from 'react-dropzone';
import FriendsCarousel from './FriendsCarousel';
import Post from './Post';
import Modal from 'react-modal';
import './Profile.css';
import './Post.css';

Modal.setAppElement('#root');

function Profile() {
  const [user, setUser] = useState({});
  const [avatar, setAvatar] = useState(null);
  const [posts, setPosts] = useState([]);
  const [postContent, setPostContent] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [userMedia, setUserMedia] = useState([]);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const userResponse = await axios.get('https://quuqle.com:3001/profile');
        setUser(userResponse.data);

        const postsResponse = await axios.get('https://quuqle.com:3001/my_posts');
        setPosts(postsResponse.data);

        const mediaResponse = await axios.get('https://quuqle.com:3001/user_images');
        setUserMedia(mediaResponse.data);
      } catch (error) {
        setError(error.response ? error.response.data.message : error.message);
      }
    }
    fetchUserData();
  }, []);

  const handleAvatarChange = (e) => {
    setAvatar(e.target.files[0]);
  };

  const handleAvatarUpload = async () => {
    const formData = new FormData();
    formData.append('avatar', avatar);

    try {
      const response = await axios.post('https://quuqle.com:3001/update-avatar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setUser((prevUser) => ({ ...prevUser, avatar: response.data.avatar }));
      alert('Avatar updated successfully');
    } catch (error) {
      console.log('Error uploading avatar:', error.response ? error.response.data : error.message);
    }
  };

  const handlePostSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('content', postContent);
    if (selectedFile) {
      formData.append('media', selectedFile);
    }

    try {
      const response = await axios.post('https://quuqle.com:3001/posts', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      const newPost = {
        ...response.data,
        username: user.username,
        avatar: user.avatar,
      };
      setPosts([newPost, ...posts]); // Prepend the new post to the posts array
      setPostContent('');
      setSelectedFile(null);

      // Refresh user media
      const mediaResponse = await axios.get('https://quuqle.com:3001/user_images');
      setUserMedia(mediaResponse.data);

    } catch (error) {
      console.log('Error creating post:', error.response ? error.response.data : error.message);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*,video/*' });

  const openModal = (mediaUrl) => {
    setSelectedMedia(mediaUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMedia(null);
  };

  return (
    <div className="profile-container">
     <FriendsCarousel />
      <div className="user-info">
        <img src={`http://quuqle.com:3001/uploads/avatars/${user.avatar}`} alt="User Avatar" className="user-avatar" />
        <div className="user-details">
          <h2>{user.username}</h2>
          <p>{user.email}</p>
        </div>
        <div className="upload-avatar">
          <input type="file" onChange={handleAvatarChange} />
          <button onClick={handleAvatarUpload}>Upload Avatar</button>
        </div>
      </div>
      <div className="main-content">
        <div className="sidebar">
          <div className="user-media">
            <h3>Media</h3>
            <div className="media-grid">
              {userMedia.map((media, index) => (
                <div key={index} className="media-grid-item" onClick={() => openModal(media.mediaUrl)}>
                  {media.mediaUrl.endsWith('.mp4') ? (
                    <video src={`http://quuqle.com:3001/uploads/${media.mediaUrl}`} alt="User upload" />
                  ) : (
                    <img src={`http://quuqle.com:3001/uploads/${media.mediaUrl}`} alt="User upload" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="content">
          <div className="create-post">
            <form onSubmit={handlePostSubmit}>
              <textarea 
                value={postContent}
                onChange={(e) => setPostContent(e.target.value)}
                placeholder="Mitä mietit?"
                className="create-post-textarea"
              />
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here...</p>
                ) : (
                  <p>Drag 'n' drop some files here, or click to select files</p>
                )}
              </div>
              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
              <button type="submit" className="create-post-button">Post</button>
            </form>
          </div>
          <div className="posts-section">
            <h3>Posts</h3>
            {posts.map(post => (
              <Post key={post.id} post={post} showAll={true} />
            ))}
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="modal" overlayClassName="modal-overlay">
        {selectedMedia && selectedMedia.endsWith('.mp4') ? (
          <video controls className="modal-media">
            <source src={`http://quuqle.com:3001/uploads/${selectedMedia}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img src={`http://quuqle.com:3001/uploads/${selectedMedia}`} alt="Full size" className="modal-media" />
        )}
      </Modal>
    </div>
  );
}

export default Profile;












/*
import React, { useState, useEffect, useCallback } from 'react';
import axios from '../axiosConfig';
import { useDropzone } from 'react-dropzone';
import FriendsCarousel from './FriendsCarousel';
import Post from './Post';
import Modal from 'react-modal';
import './Profile.css';
import './Post.css';

Modal.setAppElement('#root');

function Profile() {
  const [user, setUser] = useState({});
  const [avatar, setAvatar] = useState(null);
  const [posts, setPosts] = useState([]);
  const [postContent, setPostContent] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [userMedia, setUserMedia] = useState([]);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const userResponse = await axios.get('http://quuqle.com:3001/profile');
        setUser(userResponse.data);

        const postsResponse = await axios.get('http://quuqle.com:3001/my_posts');
        setPosts(postsResponse.data);

        const mediaResponse = await axios.get('http://quuqle.com:3001/user_images');
        setUserMedia(mediaResponse.data);
      } catch (error) {
        setError(error.response ? error.response.data.message : error.message);
      }
    }
    fetchUserData();
  }, []);

  const handleAvatarChange = (e) => {
    setAvatar(e.target.files[0]);
  };

  const handleAvatarUpload = async () => {
    const formData = new FormData();
    formData.append('avatar', avatar);

    try {
      const response = await axios.post('http://quuqle.com:3001/update-avatar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setUser((prevUser) => ({ ...prevUser, avatar: response.data.avatar }));
      alert('Avatar updated successfully');
    } catch (error) {
      console.log('Error uploading avatar:', error.response ? error.response.data : error.message);
    }
  };

  const handlePostSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('content', postContent);
    if (selectedFile) {
      formData.append('media', selectedFile);
    }

    try {
      const response = await axios.post('http://quuqle.com:3001/posts', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setPosts([response.data, ...posts]);
      setPostContent('');
      setSelectedFile(null);

      // Refresh user media
      const mediaResponse = await axios.get('http://quuqle.com:3001/user_images');
      setUserMedia(mediaResponse.data);

    } catch (error) {
      console.log('Error creating post:', error.response ? error.response.data : error.message);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*,video/*' });

  const openModal = (mediaUrl) => {
    setSelectedMedia(mediaUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMedia(null);
  };

  return (
    <div className="profile-container">
      <div className="user-info">
        <img src={`http://quuqle.com:3001/uploads/avatars/${user.avatar}`} alt="User Avatar" className="user-avatar" />
        <div className="user-details">
          <h2>{user.username}</h2>
          <p>{user.email}</p>
        </div>
        <div className="upload-avatar">
          <input type="file" onChange={handleAvatarChange} />
          <button onClick={handleAvatarUpload}>Upload Avatar</button>
        </div>
      </div>
      <div className="main-content">
        <div className="sidebar">
          <div className="user-media">
            <h3>Media</h3>
            <div className="media-grid">
              {userMedia.map((media, index) => (
                <div key={index} className="media-grid-item" onClick={() => openModal(media.mediaUrl)}>
                  {media.mediaUrl.endsWith('.mp4') ? (
                    <video src={`http://quuqle.com:3001/uploads/${media.mediaUrl}`} alt="User upload" />
                  ) : (
                    <img src={`http://quuqle.com:3001/uploads/${media.mediaUrl}`} alt="User upload" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="content">
          <div className="create-post">
            <form onSubmit={handlePostSubmit}>
              <textarea 
                value={postContent}
                onChange={(e) => setPostContent(e.target.value)}
                placeholder="What's on your mind?"
                className="create-post-textarea"
              />
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here...</p>
                ) : (
                  <p>Drag 'n' drop some files here, or click to select files</p>
                )}
              </div>
              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
              <button type="submit" className="create-post-button">Post</button>
            </form>
          </div>
          <div className="posts-section">
            <h3>Posts</h3>
            {posts.map(post => (
              <Post key={post.id} post={post} showAll={true} />
            ))}
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="modal" overlayClassName="modal-overlay">
        {selectedMedia && selectedMedia.endsWith('.mp4') ? (
          <video controls className="modal-media">
            <source src={`http://quuqle.com:3001/uploads/${selectedMedia}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img src={`http://quuqle.com:3001/uploads/${selectedMedia}`} alt="Full size" className="modal-media" />
        )}
      </Modal>
    </div>
  );
}

export default Profile;
*/










/*import React, { useState, useEffect, useCallback } from 'react';
import axios from '../axiosConfig';
import { useDropzone } from 'react-dropzone';
import FriendsCarousel from './FriendsCarousel';
import Post from './Post';
import Modal from 'react-modal';
import './Profile.css';
import './Post.css';

Modal.setAppElement('#root');

function Profile() {
  const [user, setUser] = useState({});
  const [avatar, setAvatar] = useState(null);
  const [posts, setPosts] = useState([]);
  const [postContent, setPostContent] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [userImages, setUserImages] = useState([]);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const userResponse = await axios.get('http://quuqle.com:3001/profile');
        setUser(userResponse.data);

        const postsResponse = await axios.get('http://quuqle.com:3001/my_posts');
        setPosts(postsResponse.data);

        const imagesResponse = await axios.get('http://quuqle.com:3001/user_images');
        setUserImages(imagesResponse.data);
      } catch (error) {
        setError(error.response ? error.response.data.message : error.message);
      }
    }
    fetchUserData();
  }, []);

  const handleAvatarChange = (e) => {
    setAvatar(e.target.files[0]);
  };

  const handleAvatarUpload = async () => {
    const formData = new FormData();
    formData.append('avatar', avatar);

    try {
      const response = await axios.post('http://quuqle.com:3001/update-avatar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setUser((prevUser) => ({ ...prevUser, avatar: response.data.avatar }));
      alert('Avatar updated successfully');
    } catch (error) {
      console.log('Error uploading avatar:', error.response ? error.response.data : error.message);
    }
  };

  const handlePostSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('content', postContent);
    if (selectedFile) {
      formData.append('media', selectedFile);
    }

    try {
      const response = await axios.post('http://quuqle.com:3001/posts', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setPosts([response.data, ...posts]);
      setPostContent('');
      setSelectedFile(null);

      // Refresh user images
      const imagesResponse = await axios.get('http://quuqle.com:3001/user_images');
      setUserImages(imagesResponse.data);

    } catch (error) {
      console.log('Error creating post:', error.response ? error.response.data : error.message);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*,video/*' });

  const openModal = (mediaUrl) => {
    setSelectedMedia(mediaUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMedia(null);
  };

  return (
    <div className="profile-container">
      <div className="user-info">
        <img src={`http://quuqle.com:3001/uploads/avatars/${user.avatar}`} alt="User Avatar" className="user-avatar" />
        <div className="user-details">
          <h2>{user.username}</h2>
          <p>{user.email}</p>
        </div>
        <div className="upload-avatar">
          <input type="file" onChange={handleAvatarChange} />
          <button onClick={handleAvatarUpload}>Upload Avatar</button>
        </div>
      </div>
      <div className="main-content">
        <div className="sidebar">
          <div className="user-images">
            <h3>Kuvat</h3>
            <div className="image-grid">
              {userImages.map((image, index) => (
                <div key={index} className="image-grid-item" onClick={() => openModal(image.imageUrl)}>
                  <img src={`http://quuqle.com:3001/uploads/${image.imageUrl}`} alt="User upload" />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="content">
          <div className="create-post">
            <form onSubmit={handlePostSubmit}>
              <textarea 
                value={postContent}
                onChange={(e) => setPostContent(e.target.value)}
                placeholder="What's on your mind?"
                className="create-post-textarea"
              />
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here...</p>
                ) : (
                  <p>Drag 'n' drop some files here, or click to select files</p>
                )}
              </div>
              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
              <button type="submit" className="create-post-button">Post</button>
            </form>
          </div>
          <div className="posts-section">
            <h3>Posts</h3>
            {posts.map(post => (
              <Post key={post.id} post={post} showAll={true} />
            ))}
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="modal" overlayClassName="modal-overlay">
        {selectedMedia && selectedMedia.endsWith('.mp4') ? (
          <video controls className="modal-media">
            <source src={`http://quuqle.com:3001/uploads/${selectedMedia}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img src={`http://quuqle.com:3001/uploads/${selectedMedia}`} alt="Full size" className="modal-media" />
        )}
      </Modal>
    </div>
  );
}

export default Profile;
*/


























/*import React, { useState, useEffect, useCallback } from 'react';
import axios from '../axiosConfig';
import { useDropzone } from 'react-dropzone';
import FriendsCarousel from './FriendsCarousel';
import Post from './Post';
import Modal from 'react-modal';
import './Profile.css';
import './Post.css';

Modal.setAppElement('#root');

function Profile() {
  const [user, setUser] = useState({});
  const [avatar, setAvatar] = useState(null);
  const [posts, setPosts] = useState([]);
  const [postContent, setPostContent] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [userImages, setUserImages] = useState([]);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const userResponse = await axios.get('http://quuqle.com:3001/profile');
        setUser(userResponse.data);

        const postsResponse = await axios.get('http://quuqle.com:3001/my_posts');
        setPosts(postsResponse.data);

        const imagesResponse = await axios.get('http://quuqle.com:3001/user_images');
        setUserImages(imagesResponse.data);
      } catch (error) {
        setError(error.response ? error.response.data.message : error.message);
      }
    }
    fetchUserData();
  }, []);

  const handleAvatarChange = (e) => {
    setAvatar(e.target.files[0]);
  };

  const handleAvatarUpload = async () => {
    const formData = new FormData();
    formData.append('avatar', avatar);

    try {
      const response = await axios.post('http://quuqle.com:3001/update-avatar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setUser((prevUser) => ({ ...prevUser, avatar: response.data.avatar }));
      alert('Avatar updated successfully');
    } catch (error) {
      console.log('Error uploading avatar:', error.response ? error.response.data : error.message);
    }
  };

  const handlePostSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('content', postContent);
    if (selectedFile) {
      formData.append('image', selectedFile);
    }

    try {
      const response = await axios.post('http://quuqle.com:3001/posts', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setPosts([response.data, ...posts]);
      setPostContent('');
      setSelectedFile(null);

      // Refresh user images
      const imagesResponse = await axios.get('http://quuqle.com:3001/user_images');
      setUserImages(imagesResponse.data);

    } catch (error) {
      console.log('Error creating post:', error.response ? error.response.data : error.message);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <div className="profile-container">
      <div className="user-info">
        <img src={`http://quuqle.com:3001/uploads/avatars/${user.avatar}`} alt="User Avatar" className="user-avatar" />
        <div className="user-details">
          <h2>{user.username}</h2>
          <p>{user.email}</p>
        </div>
        <div className="upload-avatar">
          <input type="file" onChange={handleAvatarChange} />
          <button onClick={handleAvatarUpload}>Upload Avatar</button>
        </div>
      </div>
      <div className="main-content">
        <div className="sidebar">
          <div className="user-images">
            <h3>Kuvat</h3>
            <div className="image-grid">
              {userImages.map((image, index) => (
                <div key={index} className="image-grid-item" onClick={() => openModal(image.imageUrl)}>
                  <img src={`http://quuqle.com:3001/uploads/${image.imageUrl}`} alt="User upload" />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="content">
          <div className="create-post">
            <form onSubmit={handlePostSubmit}>
              <textarea 
                value={postContent}
                onChange={(e) => setPostContent(e.target.value)}
                placeholder="What's on your mind?"
                className="create-post-textarea"
              />
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here...</p>
                ) : (
                  <p>Drag 'n' drop some files here, or click to select files</p>
                )}
              </div>
              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
              <button type="submit" className="create-post-button">Post</button>
            </form>
          </div>
          <div className="posts-section">
            <h3>Posts</h3>
            {posts.map(post => (
              <Post key={post.id} post={post} showAll={true} />
            ))}
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="modal" overlayClassName="modal-overlay">
        {selectedImage && <img src={`http://quuqle.com:3001/uploads/${selectedImage}`} alt="Full size" className="modal-image" />}
      </Modal>
    </div>
  );
}

export default Profile;

*/













/*import React, { useState, useEffect, useCallback } from 'react';
import axios from '../axiosConfig';
import { useDropzone } from 'react-dropzone';
import FriendsCarousel from './FriendsCarousel';
import Post from './Post';
import Modal from 'react-modal';
import './Profile.css';
import './Post.css';

Modal.setAppElement('#root');

function Profile() {
  const [user, setUser] = useState({});
  const [avatar, setAvatar] = useState(null);
  const [posts, setPosts] = useState([]);
  const [postContent, setPostContent] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [userImages, setUserImages] = useState([]);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const userResponse = await axios.get('http://quuqle.com:3001/profile');
        setUser(userResponse.data);

        const postsResponse = await axios.get('http://quuqle.com:3001/my_posts');
        setPosts(postsResponse.data);

        const imagesResponse = await axios.get('http://quuqle.com:3001/user_images');
        setUserImages(imagesResponse.data);
      } catch (error) {
        setError(error.response ? error.response.data.message : error.message);
      }
    }
    fetchUserData();
  }, []);

  const handleAvatarChange = (e) => {
    setAvatar(e.target.files[0]);
  };

  const handleAvatarUpload = async () => {
    const formData = new FormData();
    formData.append('avatar', avatar);

    try {
      const response = await axios.post('http://quuqle.com:3001/update-avatar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setUser((prevUser) => ({ ...prevUser, avatar: response.data.avatar }));
      alert('Avatar updated successfully');
    } catch (error) {
      console.log('Error uploading avatar:', error.response ? error.response.data : error.message);
    }
  };

  const handlePostSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('content', postContent);
    if (selectedFile) {
      formData.append('image', selectedFile);
    }

    try {
      const response = await axios.post('http://quuqle.com:3001/posts', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setPosts([response.data, ...posts]);
      setPostContent('');
      setSelectedFile(null);

      // Refresh user images
      const imagesResponse = await axios.get('http://quuqle.com:3001/user_images');
      setUserImages(imagesResponse.data);

    } catch (error) {
      console.log('Error creating post:', error.response ? error.response.data : error.message);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <div className="profile-container">
      <div className="user-info">
        <img src={`http://quuqle.com:3001/uploads/avatars/${user.avatar}`} alt="User Avatar" className="user-avatar" />
        <div className="user-details">
          <h2>{user.username}</h2>
          <p>{user.email}</p>
        </div>
        <div className="upload-avatar">
          <input type="file" onChange={handleAvatarChange} />
          <button onClick={handleAvatarUpload}>Upload Avatar</button>
        </div>
      </div>
      <div className="profile-content">
        <div className="user-images">
          <h3>Kuvat</h3>
          <div className="image-grid">
            {userImages.map((image, index) => (
              <div key={index} className="image-grid-item" onClick={() => openModal(image.imageUrl)}>
                <img src={`http://quuqle.com:3001/uploads/${image.imageUrl}`} alt="User upload" />
              </div>
            ))}
          </div>
        </div>
        <div className="create-post">
          <form onSubmit={handlePostSubmit}>
            <textarea 
              value={postContent}
              onChange={(e) => setPostContent(e.target.value)}
              placeholder="What's on your mind?"
              className="create-post-textarea"
            />
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here...</p>
              ) : (
                <p>Drag 'n' drop some files here, or click to select files</p>
              )}
            </div>
            {selectedFile && <p>Selected file: {selectedFile.name}</p>}
            <button type="submit" className="create-post-button">Post</button>
          </form>
        </div>
        <div className="posts-section">
          <h3>Posts</h3>
          {posts.map(post => (
            <Post key={post.id} post={post} showAll={true} />
          ))}
        </div>
      </div>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="modal" overlayClassName="modal-overlay">
        {selectedImage && <img src={`http://quuqle.com:3001/uploads/${selectedImage}`} alt="Full size" className="modal-image" />}
      </Modal>
    </div>
  );
}

export default Profile;

*/


/*import React, { useState, useEffect, useCallback } from 'react';
import axios from '../axiosConfig';
import { useDropzone } from 'react-dropzone';
import FriendsCarousel from './FriendsCarousel';
import Post from './Post';
import './Profile.css';
import './Post.css';

function Profile() {
  const [user, setUser] = useState({});
  const [avatar, setAvatar] = useState(null);
  const [posts, setPosts] = useState([]);
  const [postContent, setPostContent] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [userImages, setUserImages] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchUserData() {
      try {
        const userResponse = await axios.get('http://quuqle.com:3001/profile');
        setUser(userResponse.data);

        const postsResponse = await axios.get('http://quuqle.com:3001/my_posts');
        setPosts(postsResponse.data);

        const imagesResponse = await axios.get('http://quuqle.com:3001/user_images');
        setUserImages(imagesResponse.data);
      } catch (error) {
        setError(error.response ? error.response.data.message : error.message);
      }
    }
    fetchUserData();
  }, []);

  const handleAvatarChange = (e) => {
    setAvatar(e.target.files[0]);
  };

  const handleAvatarUpload = async () => {
    const formData = new FormData();
    formData.append('avatar', avatar);

    try {
      const response = await axios.post('http://quuqle.com:3001/update-avatar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setUser((prevUser) => ({ ...prevUser, avatar: response.data.avatar }));
      alert('Avatar updated successfully');
    } catch (error) {
      console.log('Error uploading avatar:', error.response ? error.response.data : error.message);
    }
  };

  const handlePostSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('content', postContent);
    if (selectedFile) {
      formData.append('image', selectedFile);
    }

    try {
      const response = await axios.post('http://quuqle.com:3001/posts', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setPosts([response.data, ...posts]);
      setPostContent('');
      setSelectedFile(null);

      // Refresh user images
      const imagesResponse = await axios.get('http://quuqle.com:3001/user_images');
      setUserImages(imagesResponse.data);

    } catch (error) {
      console.log('Error creating post:', error.response ? error.response.data : error.message);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="profile-container">
      <div className="user-info">
        <img src={`http://quuqle.com:3001/uploads/avatars/${user.avatar}`} alt="User Avatar" className="user-avatar" />
        <div className="user-details">
          <h2>{user.username}</h2>
          <p>{user.email}</p>
        </div>
        <div className="upload-avatar">
          <input type="file" onChange={handleAvatarChange} />
          <button onClick={handleAvatarUpload}>Upload Avatar</button>
        </div>
      </div>
      <div className="main-content">
        <div className="sidebar">
          <div className="user-images">
            <h3>Kuvat</h3>
            <div className="image-grid">
              {userImages.map((image, index) => (
                <div key={index} className="image-grid-item">
                  <img src={`http://quuqle.com:3001/uploads/${image.imageUrl}`} alt="User upload" />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="content">
          <div className="create-post">
            <form onSubmit={handlePostSubmit}>
              <textarea 
                value={postContent}
                onChange={(e) => setPostContent(e.target.value)}
                placeholder="What's on your mind?"
                className="create-post-textarea"
              />
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here...</p>
                ) : (
                  <p>Drag 'n' drop some files here, or click to select files</p>
                )}
              </div>
              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
              <button type="submit" className="create-post-button">Post</button>
            </form>
          </div>
          <div className="posts-section">
            <h3>Posts</h3>
            {posts.map(post => (
              <Post key={post.id} post={post} showAll={true} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;

*/





/*import React, { useState, useEffect, useCallback } from 'react';
import axios from '../axiosConfig';
import { useDropzone } from 'react-dropzone';
import FriendsCarousel from './FriendsCarousel';
import Post from './Post';
import './Profile.css';
import './Post.css';

function Profile() {
  const [user, setUser] = useState({});
  const [avatar, setAvatar] = useState(null);
  const [posts, setPosts] = useState([]);
  const [postContent, setPostContent] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchUserData() {
      try {
        const userResponse = await axios.get('http://quuqle.com:3001/profile');
        setUser(userResponse.data);

        const postsResponse = await axios.get('http://quuqle.com:3001/my_posts');
        setPosts(postsResponse.data);
      } catch (error) {
        setError(error.response ? error.response.data.message : error.message);
      }
    }
    fetchUserData();
  }, []);

  const handleAvatarChange = (e) => {
    setAvatar(e.target.files[0]);
  };

  const handleAvatarUpload = async () => {
    const formData = new FormData();
    formData.append('avatar', avatar);

    try {
      const response = await axios.post('http://quuqle.com:3001/update-avatar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setUser((prevUser) => ({ ...prevUser, avatar: response.data.avatar }));
      alert('Avatar updated successfully');
    } catch (error) {
      console.log('Error uploading avatar:', error.response ? error.response.data : error.message);
    }
  };

  const handlePostSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('content', postContent);
    if (selectedFile) {
      formData.append('image', selectedFile);
    }

    try {
      const response = await axios.post('http://quuqle.com:3001/posts', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setPosts([response.data, ...posts]);
      setPostContent('');
      setSelectedFile(null);
    } catch (error) {
      console.log('Error creating post:', error.response ? error.response.data : error.message);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="profile-container">
      <FriendsCarousel />
      {error && <div className="error">{error}</div>}
      <div className="user-info">
        <img src={`http://quuqle.com:3001/uploads/avatars/${user.avatar}`} alt="User Avatar" className="user-avatar" />
        <div className="user-details">
          <h2>{user.username}</h2>
          <p>{user.email}</p>
        </div>
        <div className="upload-avatar">
          <input type="file" onChange={handleAvatarChange} />
          <button onClick={handleAvatarUpload}>Upload Avatar</button>
        </div>
      </div>
      <div className="profile-content">
        <div className="create-post">
          <form onSubmit={handlePostSubmit}>
            <textarea 
              value={postContent}
              onChange={(e) => setPostContent(e.target.value)}
              placeholder="What's on your mind?"
              className="create-post-textarea"
            />
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here...</p>
              ) : (
                <p>Drag 'n' drop some files here, or click to select files</p>
              )}
            </div>
            {selectedFile && <p>Selected file: {selectedFile.name}</p>}
            <button type="submit" className="create-post-button">Post</button>
          </form>
        </div>
        <div className="posts-section">
          <h3>Posts</h3>
          {posts.map(post => (
            <Post key={post.id} post={post} showAll={true} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Profile;
*/




























/*import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import FriendsCarousel from './FriendsCarousel';
import Post from './Post';
import './Profile.css';
import './Post.css';

function Profile() {
  const [user, setUser] = useState({});
  const [avatar, setAvatar] = useState(null);
  const [posts, setPosts] = useState([]);
  const [postContent, setPostContent] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchUserData() {
      try {
        const userResponse = await axios.get('http://quuqle.com:3001/profile');
        setUser(userResponse.data);

        const postsResponse = await axios.get('http://quuqle.com:3001/my_posts');
        setPosts(postsResponse.data);
      } catch (error) {
        setError(error.response ? error.response.data.message : error.message);
      }
    }
    fetchUserData();
  }, []);

  const handleAvatarChange = (e) => {
    setAvatar(e.target.files[0]);
  };

  const handleAvatarUpload = async () => {
    const formData = new FormData();
    formData.append('avatar', avatar);

    try {
      const response = await axios.post('http://quuqle.com:3001/update-avatar', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setUser((prevUser) => ({ ...prevUser, avatar: response.data.avatar }));
      alert('Avatar updated successfully');
    } catch (error) {
      console.log('Error uploading avatar:', error.response ? error.response.data : error.message);
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handlePostSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('content', postContent);
    if (selectedFile) {
      formData.append('image', selectedFile);
    }

    try {
      const response = await axios.post('http://quuqle.com:3001/posts', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setPosts([response.data, ...posts]);
      setPostContent('');
      setSelectedFile(null);
    } catch (error) {
      console.log('Error creating post:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className="profile-container">
      <FriendsCarousel />
      {error && <div className="error">{error}</div>}
      <div className="user-info">
        <img src={`http://quuqle.com:3001/uploads/avatars/${user.avatar}`} alt="User Avatar" className="user-avatar" />
        <div className="user-details">
          <h2>{user.username}</h2>
          <p>{user.email}</p>
        </div>
        <div className="upload-avatar">
          <input type="file" onChange={handleAvatarChange} />
          <button onClick={handleAvatarUpload}>Upload Avatar</button>
        </div>
      </div>
      <div className="profile-content">
        <div className="create-post">
          <form onSubmit={handlePostSubmit}>
            <textarea 
              value={postContent}
              onChange={(e) => setPostContent(e.target.value)}
              placeholder="What's on your mind?"
              className="create-post-textarea"
            />
            <input type="file" onChange={handleFileChange} />
            <button type="submit" className="create-post-button">Post</button>
          </form>
        </div>
        <div className="posts-section">
          <h3>Posts</h3>
          {posts.map(post => (
            <Post key={post.id} post={post} showAll={true} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Profile;

*/
