import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import axios from './axiosConfig';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Register from './components/Register';
import Login from './components/Login';
import NewsFeed from './components/NewsFeed';
import CreatePost from './components/CreatePost';
import Chat from './components/Chat';
import SendFriendRequest from './components/SendFriendRequest';
import FriendRequests from './components/FriendRequests';
import Profile from './components/Profile';
import Contacts from './components/Contacts';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('https://quuqle.com:3001/auth/check', { withCredentials: true });
        if (response.status === 200) {
          setIsAuthenticated(true);
          setUserId(response.data.userId);
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };
    checkAuth();
  }, []);

  const handleLogout = async () => {
    try {
      await axios.post('https://quuqle.com:3001/auth/logout', {}, { withCredentials: true });
      setIsAuthenticated(false);
      setUserId(null);
    } catch (error) {
      console.error('Logout error:', error.response ? error.response.data : error.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="app">
        {isAuthenticated && <Header handleLogout={handleLogout} />}
        <div className="app__body">
          <div className="app__content">
            <Switch>
              <Route path="/register" component={Register} />
              <Route path="/login">
                <Login setIsAuthenticated={setIsAuthenticated} />
              </Route>
              <Route path="/newsfeed">
                {isAuthenticated ? <NewsFeed /> : <Redirect to="/login" />}
              </Route>
              <Route path="/create-post">
                {isAuthenticated ? <CreatePost /> : <Redirect to="/login" />}
              </Route>
              <Route path="/chat/:friendId" render={(props) => (
                isAuthenticated ? <Chat {...props} friendId={props.match.params.friendId} userId={userId} /> : <Redirect to="/login" />
              )} />
              <Route path="/send-friend-request">
                {isAuthenticated ? <SendFriendRequest /> : <Redirect to="/login" />}
              </Route>
              <Route path="/friend-requests">
                {isAuthenticated ? <FriendRequests /> : <Redirect to="/login" />}
              </Route>
              <Route path="/profile">
                {isAuthenticated ? <Profile /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/">
                {isAuthenticated ? <Redirect to="/newsfeed" /> : <Redirect to="/login" />}
              </Route>
              <Route component={() => <h1>404 Not Found</h1>} />
            </Switch>
          </div>
        </div>
        {isAuthenticated && <Contacts userId={userId} />}
      </div>
    </Router>
  );
}

export default App;


/*import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import axios from './axiosConfig';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Register from './components/Register';
import Login from './components/Login';
import NewsFeed from './components/NewsFeed';
import CreatePost from './components/CreatePost';
import Chat from './components/Chat';
import SendFriendRequest from './components/SendFriendRequest';
import FriendRequests from './components/FriendRequests';
import Profile from './components/Profile';
import Contacts from './components/Contacts';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('http://quuqle.com:3001/auth/check', { withCredentials: true });
        if (response.status === 200) {
          setIsAuthenticated(true);
          setUserId(response.data.userId);
          console.log('User ID:', response.data.userId); // Debug log for userId
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };
    checkAuth();
  }, []);

  const handleLogout = async () => {
    try {
      await axios.post('http://quuqle.com:3001/auth/logout', {}, { withCredentials: true });
      setIsAuthenticated(false);
      setUserId(null); // Reset the userId
    } catch (error) {
      console.error('Logout error:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <Router>
      <div className="app">
        {isAuthenticated && <Header handleLogout={handleLogout} />}
        <div className="app__body">
          <div className="app__content">
            <Switch>
              <Route path="/register" component={Register} />
              <Route path="/login">
                <Login setIsAuthenticated={setIsAuthenticated} />
              </Route>
              <Route path="/newsfeed">
                {isAuthenticated ? <NewsFeed /> : <Redirect to="/login" />}
              </Route>
              <Route path="/create-post">
                {isAuthenticated ? <CreatePost /> : <Redirect to="/login" />}
              </Route>
              <Route path="/chat/:friendId" render={(props) => (
                isAuthenticated ? <Chat {...props} friendId={props.match.params.friendId} userId={userId} /> : <Redirect to="/login" />
              )} />
              <Route path="/send-friend-request">
                {isAuthenticated ? <SendFriendRequest /> : <Redirect to="/login" />}
              </Route>
              <Route path="/friend-requests">
                {isAuthenticated ? <FriendRequests /> : <Redirect to="/login" />}
              </Route>
              <Route path="/profile">
                {isAuthenticated ? <Profile /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/" component={Login} />
              <Route component={() => <h1>404 Not Found</h1>} />
            </Switch>
          </div>
        </div>
        {isAuthenticated && <Contacts />}}
      </div>
    </Router>
  );
}

export default App;
*/

/*
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
//import axios from 'axios';
import axios from './axiosConfig';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Register from './components/Register';
import Login from './components/Login';
import NewsFeed from './components/NewsFeed';
import CreatePost from './components/CreatePost';
import Chat from './components/Chat';
import SendFriendRequest from './components/SendFriendRequest';
import FriendRequests from './components/FriendRequests';
import Profile from './components/Profile';
import Contacts from './components/Contacts';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('http://quuqle.com:3001/auth/check', { withCredentials: true });
        if (response.status === 200) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    };
    checkAuth();
  }, []);

  const handleLogout = async () => {
    try {
      await axios.post('http://quuqle.com:3001/auth/logout', {}, { withCredentials: true });
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Logout error:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <Router>
      <div className="app">
        {isAuthenticated && <Header handleLogout={handleLogout} />}
        <div className="app__body">
          {isAuthenticated && <Sidebar />}
          <div className="app__content">
            <Switch>
              <Route path="/register" component={Register} />
              <Route path="/login">
                <Login setIsAuthenticated={setIsAuthenticated} />
              </Route>
              <Route path="/newsfeed">
                {isAuthenticated ? <NewsFeed /> : <Redirect to="/login" />}
              </Route>
              <Route path="/create-post">
                {isAuthenticated ? <CreatePost /> : <Redirect to="/login" />}
              </Route>
              <Route path="/chat/:friendId" render={(props) => (
                isAuthenticated ? <Chat {...props} friendId={props.match.params.friendId} /> : <Redirect to="/login" />
              )} />
              <Route path="/send-friend-request">
                {isAuthenticated ? <SendFriendRequest /> : <Redirect to="/login" />}
              </Route>
              <Route path="/friend-requests">
                {isAuthenticated ? <FriendRequests /> : <Redirect to="/login" />}
              </Route>
              <Route path="/profile">
                {isAuthenticated ? <Profile /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/" component={Login} />
              <Route component={() => <h1>404 Not Found</h1>} />
            </Switch>
          </div>
        </div>
        {isAuthenticated && <Contacts />}
      </div>
    </Router>
  );
}

export default App;

*/
