import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig';
import './NewsFeed.css'; // Add CSS file for styling
import Post from './Post';

function NewsFeed() {
  const [user, setUser] = useState({});
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const userResponse = await axios.get('https://quuqle.com:3001/profile', {
          withCredentials: true
        });
        setUser(userResponse.data);

        const postsResponse = await axios.get('https://quuqle.com:3001/posts', {
          withCredentials: true
        });
        setPosts(postsResponse.data);
      } catch (error) {
        console.log('Error fetching data:', error.response ? error.response.data : error.message);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="newsfeed-container">
      <div className="user-info">
        <img src={`https://quuqle.com:3001/uploads/avatars/${user.avatar}`} alt="User Avatar" className="user-avatar" />
        <div className="user-details">
          <h2>{user.username}</h2>
          <p>{user.email}</p>
        </div>
      </div>
      <div className="newsfeed-content">
        <div className="posts-section">
          <h3>NewsFeed</h3>
          {posts.map(post => (
            <Post key={post.id} post={post} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default NewsFeed;

