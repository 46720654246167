import React, { useState } from 'react';
import axios from 'axios';

function CreatePost({ token }) {
  const [content, setContent] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://quuqle.com:3001/posts', { content }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('Post created successfully:', response.data);
      setContent(''); // Clear the input field after successful post creation
    } catch (error) {
      console.error('Error creating post:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div>
      <h2>Create Post</h2>
      <form onSubmit={handleSubmit}>
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="What's on your mind?"
          required
        />
        <button type="submit">Post</button>
      </form>
    </div>
  );
}

export default CreatePost;
