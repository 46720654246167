import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig';
import './FriendRequests.css';

function FriendRequests() {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    async function fetchRequests() {
      try {
        const response = await axios.get('https://quuqle.com:3001/pending-friend-requests', {
          withCredentials: true
        });

        if (response.status !== 200) {
          console.error('Error fetching friend requests:', response.data);
          return;
        }

        setRequests(response.data);
      } catch (error) {
        console.error('Error fetching friend requests:', error);
      }
    }
    fetchRequests();
  }, []);

  const handleAccept = async (requestId) => {
    try {
      const response = await axios.post('https://quuqle.com:3001/accept-friend-request', { requestId }, {
        withCredentials: true
      });

      if (response.status !== 200) {
        console.error('Error accepting friend request:', response.data);
        return;
      }

      console.log('Friend request accepted successfully:', response.data);
      // Refresh the list of requests after accepting one
      setRequests(requests.filter(request => request.id !== requestId));
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <div>
      <h2>Friend Requests</h2>
      {requests.length === 0 ? (
        <p>No pending friend requests</p>
      ) : (
        <ul>
          {requests.map(request => (
            <li key={request.id}>
              <p>Friend request from user ID: {request.user_id}</p>
              <button onClick={() => handleAccept(request.id)}>Accept</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default FriendRequests;


/*
import React, { useEffect, useState } from 'react';

function FriendRequests({ token }) {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    async function fetchRequests() {
      try {
        const response = await fetch('http://quuqle.com:3001/pending-friend-requests', {
          headers: { 
            'Authorization': `Bearer ${token}`  // Include token here
          }
        });

        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error fetching friend requests:', errorData);
          return;
        }

        const data = await response.json();
        setRequests(data);
      } catch (error) {
        console.error('Error fetching friend requests:', error);
      }
    }
    fetchRequests();
  }, [token]);

  const handleAccept = async (requestId) => {
    try {
      const response = await fetch('http://quuqle.com:3001/accept-friend-request', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Include token here
        },
        body: JSON.stringify({ requestId })
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error accepting friend request:', errorData);
        return;
      }

      const data = await response.json();
      console.log('Friend request accepted successfully:', data);
      // Refresh the list of requests after accepting one
      setRequests(requests.filter(request => request.id !== requestId));
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <div>
      <h2>Friend Requests</h2>
      {requests.length === 0 ? (
        <p>No pending friend requests</p>
      ) : (
        <ul>
          {requests.map(request => (
            <li key={request.id}>
              <p>Friend request from user ID: {request.user_id}</p>
              <button onClick={() => handleAccept(request.id)}>Accept</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default FriendRequests;
*/
